import { TaskStatus } from '../../../common/enums/TaskStatus';
import { TaskType } from '../../../common/enums/TaskType';
import { TaskUserStatus } from '../../../common/enums/TaskUserStatus';
import { serverAxios } from '../api';
import { IContainerFile } from '../cms/FileContainerModel';

export interface IGetTasksResult {
  stats: IStats;
  tasks: ITask[];
}

export interface IStats {
  availableTaskCount: number;
  returnedTaskCount: number;
}
export interface ITask {
  id: number;
  message?: string;
  createdDate: Date;
  lastUpdatedDate: Date;
  completedDate?: Date;
  dueDate?: Date;
  taskStatusId: TaskStatus;
  displayTaskStatus: string;
  taskTypeId: TaskType;
  createdByUserId: number;
  createdByUserName: string;
  createdByUserEmail: string;
  createdByUserInitials: string;
  suitabilityId: number;
  suitabilityCode: string;
  suitabilityTitle: string;
  projectNumber: string;
  numberOfSupportFiles?: number | null;
  isSingleApproverRequired: boolean;
  taskFileContainers: ITaskFileContainer[];
  taskUsers: ITaskUser[];
}

export interface ITaskFileContainer {
  fileId: number;
  fileRevisionId: number;
  releasedFileId: number | null;
  fileTitle: string;
  hasDocumentViewer: boolean;
  containerFiles: IContainerFile[];
}

export interface ITaskUser {
  userId: number;
  name: string;
  email: string;
  initials: string;
  taskUserStatusId: TaskUserStatus;
  message?: string;
  completedDate?: Date;
}

export interface IGetTaskRequest {
  filter: {
    createdByUser?: boolean;
    awaitingReviewByUser?: boolean;
    programmeId?: number;
    projectNumber?: string;
    pending?: boolean;
    filterByUserTaskTeam?: boolean;
    filterByUserDeliveryTeam?: boolean;
    filterByOverdue?: boolean;
    suitabilityIdsFilter?: number[];
  };
  status: {
    awaitingReview?: boolean;
    inProgress?: boolean;
    approved?: boolean;
    rejected?: boolean;
    overdue?: boolean;
    withdrawn?: boolean;
  };
  type: {
    taskTeamReview?: boolean;
    shareApproval?: boolean;
    deliveryTeamReview?: boolean;
    publishAuthorise?: boolean;
    publishAccept?: boolean;
  };
  search?: string;
  suitabilityIdsFilter?: number[];
  sorting: {
    sortProgrammeTitle?: boolean;
    sortProjectNumber?: boolean;
    sortProjectTitle?: boolean;
    sortLastUpdatedDate?: boolean;
    sortCreatedDate?: boolean;
    sortTaskTypeId?: boolean;
    sortDueDate?: boolean;
  };
}

export async function getTasks(request: IGetTaskRequest, abortSignal?: AbortSignal): Promise<IGetTasksResult> {
  const result = await serverAxios.post<IGetTasksResult>(
    'api/task/list',
    {
      ...request.filter,
      ...request.status,
      ...request.type,
      search: request.search,
      suitabilityIdsFilter: request.suitabilityIdsFilter,
      ...request.sorting,
    },
    {
      signal: abortSignal,
    }
  );
  return result.data;
}
