import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ComboBox, Tooltip } from '@aurecon-creative-technologies/styleguide';
import ActionBar from '../shared/ActionBar';
import ActionBarSection from '../shared/ActionBarSection';
import Style from './styles/TabContainerActionBar.module.scss';
import { classNames } from '../../utils/miscUtils';
import { TabStore } from './TabStore';
import SearchBar from '../shared/SearchBar';
import { SortOption } from './Types';
import TasksStore from './TasksStore';
import SecondaryIconButton from '../shared/SecondaryIconButton';
import { ICSVData } from '../../common/interfaces/csvData';
import { CSVLink } from 'react-csv';
import { TaskStatusText } from '../../common/constants/TaskStatusText';
import { TaskTypeText } from '../../common/constants/TaskTypeText';
import { FILE_CREATED_DATE, dateTimeFormat, formatDate } from '../../utils/dateUtils';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import { ITaskExportData } from '../../models/tasks/ITaskExportData';
import { TaskStatus } from '../../common/enums/TaskStatus';

const defaultSelected = -1;
export interface ITabContainerActionBar {
  store: TabStore;
}

const CSVHeaders = [
  { label: 'Task ID', key: 'id' },
  { label: 'Status', key: 'taskStatus' },
  { label: 'Reason', key: 'reason' },
  { label: 'Workflow', key: 'workflow' },
  { label: 'Suitability', key: 'suitability' },
  { label: 'No Files', key: 'numberOfFiles' },
  { label: 'Message', key: 'message' },
  { label: 'Start Date', key: 'startDate' },
  { label: 'Last Updated', key: 'lastUpdatedDate' },
  { label: 'Due Date', key: 'dueDate' },
  { label: 'Assigned By', key: 'assignedBy' },
  { label: 'No Support Files', key: 'numberOfSupportFiles' },
  { label: 'Require all users', key: 'requiredAllUsers' },
  { label: 'Assigned users', key: 'assignedUsers' },
];

const TabContainerActionBar: FC<ITabContainerActionBar> = ({ store }) => {
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [exporting, setExporting] = useState(false);
  const [exportData, setExportData] = useState<ICSVData<ITaskExportData> | null>(null);

  useEffect(() => {
    if (exporting) {
      csvLinkRef?.current?.link.click();
      setExporting(false);
    }
  }, [exporting]);

  const onExportTasks = () => {
    const data: ITaskExportData[] = store.tasks.map((t) => {
      const reasons = t.taskUsers.filter((u) => !!u.message).map((u) => u.message);
      return {
        id: t.id,
        taskStatus: t.displayTaskStatus ?? TaskStatusText[t.taskStatusId],
        reason: t.taskStatusId === TaskStatus.Rejected ? reasons.join(';') : '',
        workflow: TaskTypeText[t.taskTypeId],
        suitability: `${t.suitabilityCode} ${t.suitabilityTitle}`,
        numberOfFiles: t.taskFileContainers.length,
        message: t.message?.toString().trim(),
        startDate: formatDate(t.createdDate),
        lastUpdatedDate: formatDate(t.lastUpdatedDate),
        dueDate: formatDate(t.dueDate),
        assignedBy: t.createdByUserEmail,
        numberOfSupportFiles: t.numberOfSupportFiles ?? 0,
        requiredAllUsers: t.isSingleApproverRequired ? 'False' : 'True',
        assignedUsers: t.taskUsers.map((u) => u.email).join(';'),
      };
    });

    const projectName = NavBarSelectorStore.selectedItem?.project?.title ?? '';
    setExportData({
      headers: CSVHeaders,
      data,
      filename: `${projectName}-tasks${dateTimeFormat(new Date(), FILE_CREATED_DATE)}`,
    });
    setExporting(true);
  };
  return (
    <ActionBar>
      <ActionBarSection className={Style.actionBar}>
        <div className={Style.actionBarLabel}>Filter</div>
        <ComboBox
          placeholder="-"
          toggleAllLabel="All Statuses"
          showIcon={false}
          cssClass={Style.dropdown}
          selectedMultipleItems={!store.selectedStatus.length ? [] : store.selectedStatus}
          onSelectMultipleItems={(values) => store.setSelectedStatus(values.map((m) => m.id))}
          onClear={() => store.setSelectedStatus(defaultSelected)}
          options={store.statusItems}
          multiple></ComboBox>
        <ComboBox
          placeholder="-"
          toggleAllLabel="All Workflow"
          showIcon={false}
          cssClass={Style.dropdown}
          selectedMultipleItems={!store.selectedType.length ? [] : store.selectedType}
          onSelectMultipleItems={(values) => store.setSelectedType(values.map((m) => m.id))}
          onClear={() => store.setSelectedType(defaultSelected)}
          options={store.typeItems}
          multiple></ComboBox>
        <ComboBox
          placeholder="-"
          toggleAllLabel="All Suitability"
          showIcon={false}
          cssClass={Style.dropdown}
          selectedMultipleItems={!store.selectedSuitabilities.length ? [] : store.selectedSuitabilities}
          onSelectMultipleItems={(values) => store.setSelectedSuitabilities(values.map((m) => m.id))}
          onClear={() => store.setSelectedSuitabilities([])}
          options={store.suitabilityItems}
          multiple></ComboBox>
        <div className={classNames(Style.actionBarLabel, Style.marginBetweenFiltersAndSort)}>Sort</div>
        <ComboBox
          placeholder="Sort"
          showIcon={false}
          cssClass={Style.dropdown}
          selected={store.selectedSort.toString()}
          onSelect={(item) => store.setSelectedSort(Number(item?.id))}
          onClear={() => store.setSelectedSort(SortOption.LastUpdatedDate)}
          options={store.sortItems}></ComboBox>
        <Tooltip show={<div className={Style.tooltipText}>{'Export to CSV'}</div>} defaultUp={true}>
          <SecondaryIconButton
            disabled={store.isLoadingTasks}
            className={Style.exportButton}
            icon="file_download"
            onClick={onExportTasks}
          />
          {exportData && (
            <CSVLink
              headers={exportData?.headers ?? []}
              filename={exportData?.filename}
              data={exportData?.data ?? []}
              ref={csvLinkRef}
            />
          )}
        </Tooltip>
      </ActionBarSection>
      <ActionBarSection>
        <SearchBar
          searchValue={store.searchText}
          onSearch={(value) => TasksStore.setSearchValue(value)}
          placeHolderText="Search for a project ID, project name, owner name, project ..."
        />
      </ActionBarSection>
    </ActionBar>
  );
};

export default observer(TabContainerActionBar);
