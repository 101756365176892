import { computed, makeAutoObservable, reaction, runInAction } from 'mobx';
import { getTasks, IGetTaskRequest, ITask } from '../../api/authenticated/tasks/getTasks';
import { TaskStatus } from '../../common/enums/TaskStatus';
import { TaskType } from '../../common/enums/TaskType';
import { SortOption, StatusFilter, TabFilter, TypeFilter } from './Types';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import { TaskTypeText } from '../../common/constants/TaskTypeText';
import { TaskStatusText } from '../../common/constants/TaskStatusText';
import { IOption } from '@aurecon-creative-technologies/styleguide';
import { sort } from '../../utils/sortHelper';
import { SortTypes } from '../../common/enums/SortType';
import { appInsightsTrackEvent } from '../../utils/appInsights';
import { AppInsightEvent } from '../../common/constants/AppInsightEvent';

const defaultFilter = true;
export class TabStore {
  constructor(tab: TabFilter) {
    makeAutoObservable(this, { statusItems: computed, typeItems: computed, sortItems: computed }, { autoBind: true });

    this.tab = tab;

    reaction(
      () => NavBarSelectorStore.selectedItem,
      async (item) => {
        if (item) {
          runInAction(() => {
            this.abortController?.abort();
          });
          await this.loadTasks();
        }
      }
    );
  }

  public tab: TabFilter;
  public isLoadingTasks = false;
  public tasks: ITask[] = [];
  public availableTaskCount = 0;
  public selectedSuitabilities: string[] = [];
  public selectedStatus: StatusFilter[] = [];
  public selectedType: TypeFilter[] = [];
  public selectedSort: SortOption = SortOption.LastUpdatedDate;
  public searchText = '';
  public suitabilityItems: IOption[] = [];
  public abortController?: AbortController;

  public async loadTasks() {
    runInAction(() => {
      this.isLoadingTasks = true;
      this.abortController = new AbortController();
    });
    try {
      const result = await getTasks(this.buildParameters(), this.abortController?.signal);
      runInAction(() => {
        this.tasks = result.tasks;
        this.availableTaskCount = result.stats.availableTaskCount;
        if (!this.suitabilityItems.length) {
          this.suitabilityItems = sort(
            this.tasks
              .map((m) => {
                return {
                  id: m.suitabilityId.toString(),
                  value: `${m.suitabilityCode} ${m.suitabilityTitle}`,
                };
              })
              .filter((value, index, self) => self.findIndex((f) => value.id === f.id) === index),
            'value',
            SortTypes.ASC
          );
        }
      });
    } finally {
      runInAction(() => {
        this.isLoadingTasks = false;
      });
    }
  }

  private buildParameters(): IGetTaskRequest {
    const selectedStatusMap = this.selectedStatus.length ? this.selectedStatus.map(Number) : [];
    const selectedTypeMap = this.selectedType.length ? this.selectedType.map(Number) : [];
    return {
      filter: {
        programmeId: NavBarSelectorStore.selectedItem?.programme?.id,
        projectNumber: NavBarSelectorStore.selectedItem?.project?.projectNumber,
        createdByUser: this.tab === TabFilter.CreatedByMe,
        awaitingReviewByUser: this.tab === TabFilter.AwaitingMyAction,
        pending: this.tab === TabFilter.AllPendingTasks,
        filterByUserTaskTeam: this.tab === TabFilter.AllPendingTasks,
        filterByOverdue: this.selectedStatus.length ? this.selectedStatus.includes(TaskStatus.Overdue) : false,
        filterByUserDeliveryTeam:
          this.tab !== TabFilter.CreatedByMe &&
          this.tab !== TabFilter.AwaitingMyAction &&
          this.tab !== TabFilter.AllPendingTasks,
      },
      status: {
        awaitingReview: selectedStatusMap.length
          ? selectedStatusMap.includes(TaskStatus.AwaitingReview)
          : defaultFilter,
        inProgress: selectedStatusMap.length ? selectedStatusMap.includes(TaskStatus.InProgress) : defaultFilter,
        approved: selectedStatusMap.length ? selectedStatusMap.includes(TaskStatus.Approved) : defaultFilter,
        rejected: selectedStatusMap.length ? selectedStatusMap.includes(TaskStatus.Rejected) : defaultFilter,
        overdue: selectedStatusMap.length ? selectedStatusMap.includes(TaskStatus.Overdue) : defaultFilter,
        withdrawn: selectedStatusMap.length ? selectedStatusMap.includes(TaskStatus.Withdrawn) : defaultFilter,
      },
      type: {
        taskTeamReview: selectedTypeMap.length ? selectedTypeMap.includes(TaskType.TaskTeamReview) : defaultFilter,
        shareApproval: selectedTypeMap.length ? selectedTypeMap.includes(TaskType.ShareApproval) : defaultFilter,
        deliveryTeamReview: selectedTypeMap.length
          ? selectedTypeMap.includes(TaskType.DeliveryTeamReview)
          : defaultFilter,
        publishAuthorise: selectedTypeMap.length ? selectedTypeMap.includes(TaskType.PublishAuthorise) : defaultFilter,
        publishAccept: selectedTypeMap.length ? selectedTypeMap.includes(TaskType.PublishAccept) : defaultFilter,
      },
      search: this.searchText ? this.searchText : undefined, // sets to undefined if empty string
      suitabilityIdsFilter: this.selectedSuitabilities ? this.selectedSuitabilities.map(Number) : [],
      sorting: {
        sortProgrammeTitle: this.selectedSort === SortOption.ProgrammeTitle,
        sortProjectNumber: this.selectedSort === SortOption.ProjectNumber,
        sortProjectTitle: this.selectedSort === SortOption.ProjectTitle,
        sortLastUpdatedDate: this.selectedSort === SortOption.LastUpdatedDate,
        sortCreatedDate: this.selectedSort === SortOption.CreatedDate,
        sortTaskTypeId: this.selectedSort === SortOption.TaskType,
        sortDueDate: this.selectedSort === SortOption.DueDate,
      },
    };
  }

  public setSelectedStatus(newValue) {
    const oldValue = this.selectedStatus;
    runInAction(() => {
      this.selectedStatus = newValue;
    });
    if (oldValue != newValue) {
      appInsightsTrackEvent(AppInsightEvent.APPLY_TASK_FILTER, { Status: TaskStatus[newValue] ?? '' });
      this.abortController?.abort();
      this.loadTasks();
    }
  }

  public setSelectedType(newValue) {
    const oldValue = this.selectedType;
    runInAction(() => {
      this.selectedType = newValue;
    });
    if (oldValue != newValue) {
      appInsightsTrackEvent(AppInsightEvent.APPLY_TASK_FILTER, { Type: TaskType[newValue] ?? '' });
      this.abortController?.abort();
      this.loadTasks();
    }
  }

  setSelectedSuitabilities = (selectedValues) => {
    const oldValue = this.selectedSuitabilities;
    runInAction(() => {
      this.selectedSuitabilities = selectedValues;
    });
    if (oldValue != selectedValues) {
      appInsightsTrackEvent(AppInsightEvent.APPLY_TASK_FILTER, {
        Suitability: this.suitabilityItems.find((t) => t.id === `${selectedValues}`)?.value ?? '',
      });
      this.abortController?.abort();
      this.loadTasks();
    }
  };
  public setSelectedSort(newValue: SortOption) {
    const oldValue = this.selectedSort;
    runInAction(() => {
      this.selectedSort = newValue;
    });
    if (oldValue != newValue) {
      this.abortController?.abort();
      this.loadTasks();
    }
  }

  public setSearchText(newValue: string) {
    const oldValue = this.searchText;
    runInAction(() => {
      this.searchText = newValue;
    });
    if (oldValue != newValue) {
      appInsightsTrackEvent(AppInsightEvent.APPLY_TASK_FILTER, { SearchText: newValue ?? '' });
      this.abortController?.abort();
      this.loadTasks();
    }
  }

  public get statusItems(): IOption[] {
    const statusItems: IOption[] = [
      { id: TaskStatus.AwaitingReview.toString(), value: TaskStatusText[TaskStatus.AwaitingReview] },
      { id: TaskStatus.InProgress.toString(), value: TaskStatusText[TaskStatus.InProgress] },
      { id: TaskStatus.Overdue.toString(), value: TaskStatusText[TaskStatus.Overdue] },
    ];

    if (this.tab === TabFilter.CreatedByMe || this.tab === TabFilter.AllTasks) {
      statusItems.push({ id: TaskStatus.Approved.toString(), value: TaskStatusText[TaskStatus.Approved] });
      statusItems.push({ id: TaskStatus.Rejected.toString(), value: TaskStatusText[TaskStatus.Rejected] });
      statusItems.push({ id: TaskStatus.Withdrawn.toString(), value: TaskStatusText[TaskStatus.Withdrawn] });
    }

    return statusItems;
  }

  public get typeItems(): IOption[] {
    return [
      { id: TaskType.TaskTeamReview.toString(), value: TaskTypeText[TaskType.TaskTeamReview] },
      { id: TaskType.ShareApproval.toString(), value: TaskTypeText[TaskType.ShareApproval] },
      { id: TaskType.DeliveryTeamReview.toString(), value: TaskTypeText[TaskType.DeliveryTeamReview] },
      { id: TaskType.PublishAuthorise.toString(), value: TaskTypeText[TaskType.PublishAuthorise] },
      { id: TaskType.PublishAccept.toString(), value: TaskTypeText[TaskType.PublishAccept] },
    ];
  }

  public get sortItems(): IOption[] {
    const sortItems: IOption[] = [
      { id: SortOption.LastUpdatedDate.toString(), value: 'Last Updated Date' },
      { id: SortOption.DueDate.toString(), value: 'Due Date' },
      { id: SortOption.CreatedDate.toString(), value: 'Start Date' },
      { id: SortOption.TaskType.toString(), value: 'Workflow' },
    ];

    return sortItems;
  }
}
