import { SortType } from '../../../common/enums/SortType';
import { IPagedResponse } from '../../../common/models/IPagedResponse';
import { serverAxios } from '../api';

export interface ISearchTransmittalResult {
  pageResult: IPagedResponse<ISearchTransmittal>;
  transmittalSummary: ITransmittalSummary;
}

export interface ISearchTransmittalListItem extends ISearchTransmittal {
  checked: boolean;
}

export interface ISearchTransmittal {
  projectNumber: string;
  id: number;
  title: string;
  subject: string;
  message: string;
  taskTeamTitle: string;
  deliveryTeamTitle: string;
  transmittalTypeTitle: string;
  typeNamingCode: string;
  transmittalReasonId: number;
  transmittalReasonTitle: string;
  reasonNamingCode: string;
  createdByEmail: string;
  createdByName: string;
  createdByInitials: string;
  createdDate: Date;
  closed: boolean;
  closedDate: Date | null;
  dueDate: Date | null;
  flag: boolean;
  overdue: boolean;
  lastUpdated: Date;
  totalResponses: number;
  totalFiles: number;
}

export interface ITransmittalSummary {
  notificationToMe: number;
  initiatedByMe: number;
  visibleToMe: number;
}

export interface ISearchTransmittalRequest {
  notificationToMe?: boolean | null;
  initiatedByMe?: boolean | null;
  visibleToMe?: boolean | null;
  transmittalTitle?: string | null;
  transmittalTypeIds?: number[] | null;
  transmittalReasonIds?: number[] | null;
  open?: boolean | null;
  closed?: boolean | null;
  flagged?: boolean | null;
  overdue?: boolean | null;
  lastUpdated1?: Date | null;
  lastUpdated2?: Date | null;
  lastUpdatedOperator?: number | null;
  dueDate1?: Date | null;
  dueDate2?: Date | null;
  dueDateOperator?: number | null;
  initiatedDate1?: Date | null;
  initiatedDate2?: Date | null;
  initiatedDateOperator?: number | null;
  initiator?: string | null;
  taskTeamOriginator?: string | null;
  sortColumn?: string | null;
  sortDirection?: SortType;
  projectNumber: string | null;
  subject?: string | null;
  page: number;
  pageSize: number;
}

export async function searchTransmittals(
  options: ISearchTransmittalRequest,
  abortSignal?: AbortSignal
): Promise<ISearchTransmittalResult> {
  const result = await serverAxios.post<{
    transmittalSummary: ITransmittalSummary;
    pageResult: IPagedResponse<ISearchTransmittal>;
  }>(`api/transmittals/search`, options, {
    signal: abortSignal,
  });
  return {
    pageResult: result.data?.pageResult || [],
    transmittalSummary: result.data?.transmittalSummary || {
      totalInitiatedByMe: 0,
      totalMyNotifications: 0,
      totalVisibleToMe: 0,
    },
  };
}
